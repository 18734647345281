@import "/src/styles/mixins.scss";
@import "/src/styles/variables.scss";

.video-menu-content{
    width: 100%;
    height: auto;
    padding-left: 4%;
    padding-right: 4%;

    .w-25{
        width: 25%;
        padding-top: 10px;
        display: flex;
        justify-content: end;
        margin-left: 10px;
        margin-right: 10px;
    }

    .w-20{
        width: 20%;
    }

    .pt-2{
        padding-left: 10px;
        padding-top: 12px;
    }

    .w-30{
        width: 30%;
        padding-top: 15px;
    }

    .buttons-container {
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: end;
        gap: 5px;
    }

    .navbar-flex{
        padding-top: 20px;
        display: flex;
    }

    .videos-container {
        min-height: 600px;
        padding: 30px 0;
        margin-bottom: 120px;
    }

}
