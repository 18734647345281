@import "/src/styles/mixins.scss";
@import "/src/styles/variables.scss";

.video-menu-content{
    width: 100%;
    height: auto;
    padding-left: 4%;
    padding-right: 4%;

    .w-50{
        width: 50%;
        padding-top: 10px;
        display: flex;
        justify-content: end;
    }

    .w-20{
        width: 20%;
    }

    .w-30{
        width: 30%;
        padding-top: 15px;
    }

    .navbar-flex{
        padding-top: 20px;
        display: flex;
    }

    .videos-container {
        width: 100%;
        box-sizing: border-box;
        min-height: 200px;
        padding: 30px 0;
        margin-bottom: 120px;
        .video-all-over-view-container {
            box-sizing: border-box;
            width: 100%;
        }
    }

}
