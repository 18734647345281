@font-face {
  font-family: "Outfit";
  src: url("./assets/fonts/Outfit-VariableFont_wght.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

// :root {
//   --base-font-size: 12px;
// }
