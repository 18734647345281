@mixin flex($direction: row, $justify: center, $align: center, $gap: 0) {
  display: flex;
  flex-direction: $direction;
  align-items: $align;
  justify-content: $justify;
  gap: $gap;
}
@mixin block($justify: center, $align: center, $gap: 0) {
  display: block;
  align-items: $align;
  justify-content: $justify;
  gap: $gap;
}
@mixin hideScrollbar() {
  &::-webkit-scrollbar {
    display: none;
  }
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@mixin hoverEffect($scale: 1.05) {
  transition: 0.3s ease-in-out;
  &:hover {
    transform: scale($scale);
  }
}

@mixin disableDrag() {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
