@import "/src/styles/mixins.scss";

.payment-successful {
  @include flex(column, center, center, 20px);
  height: calc(100vh - 80px);

  img {
    // Your styles for img here
    height: 150px;
  }

  .title {
    // Your styles for .title here
    color: var(--text-100, #000);
    font-family: Outfit;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .description {
    // Your styles for .description here
    color: var(--text-40, #a7a7a7);
    text-align: center;
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 300px;

    span {
      // Your styles for span inside .description here
      background: var(
        --primary,
        linear-gradient(168deg, #ffda46 0%, #ffae00 100%)
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: Outfit;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration-line: underline;
    }
  }
}
