@import "/src/styles/mixins.scss";

.outline-button {
  border-radius: 40px;
  box-shadow: 0px 7px 20px 0px rgba(0, 0, 0, 0.06);
  border: 2px solid #ffae00;
  width: 100%;
  height: 70px;
  text-align: center;
  font-family: Outfit;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0;
  background: var(--primary, linear-gradient(168deg, #ffda46 0%, #ffae00 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  &:hover {
    transform: scale(1.03);
  }
}
