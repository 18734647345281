@import "/src/styles/mixins.scss";

.secondary-button {
  border-radius: 40px;
  background: var(--border, #dcdcdc);
  box-shadow: 0px 7px 20px 0px rgba(0, 0, 0, 0.06);
  border: none;
  width: 100%;
  height: 70px;
  color: var(--text-40, #a7a7a7);
  padding: 0;
  text-align: center;
  font-family: Outfit;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  &:hover {
    transform: scale(1.03);
  }
}
