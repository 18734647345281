
.admin-background-layout{
  background-color: #FCFCFC;
}
.mainDesktopLayout {
  height: 100vh;
  overflow: hidden;
  position: relative;

  .navbar-container {
    position: absolute;
    top: 0;
  }

  .content {
    padding: 120px 0 70px 0;
    @media (max-width: 980px) {
      padding: 80px 0 20px 0;
    }
  }
  .admin-content{
    padding: 84px 0 20px 0;
    height: calc(100vh - 85px);
    @media (max-width: 980px) {
      padding: 80px 0 20px 0;
    }
  }

  .footer-container {
    position: absolute;
    bottom: 0;
  }

  .logo-container {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    padding: 20px 0;
    width: 100vw;
  }

  .piq-logo {
    height: 70px;
  }
}
