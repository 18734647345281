@import "/src/styles/mixins.scss";
.signin-page {
  @include flex(row, center, start);
  height: calc(100vh - 80px);
  overflow-y: auto;
  @include hideScrollbar();
  .signin-content-area {
    width: 50%;
    max-width: 600px;
    padding: 60px;
    .title {
      color: var(--text-100, #000);
      font-family: Outfit;
      font-size: 40px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .subtitle {
      color: var(--text-40, #a7a7a7);
      font-family: Outfit;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      .subtitle-span {
        font-family: Outfit;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        cursor: pointer;
        background: var(
          --Primary-Gradient,
          linear-gradient(168deg, #ffda46 -110.09%, #ffae00 118.06%)
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .form-container {
      padding-top: 40px;
      padding-bottom: 40px;
      border: none;
      h3 {
        color: var(--text-70, #6a6a6a);
        font-family: Outfit;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 19.6px;
        margin-top: 20px;
      }
      .form-label {
        color: var(--text-70, #6a6a6a);
        font-family: Outfit;
        font-size: 18px;
        font-style: normal;
        padding-top: 20px;
        padding-bottom: 10px;
        font-weight: 400;
        line-height: normal;
      }
      .icon-input-container {
        width: 500px;
        height: 67px;
        flex-shrink: 0;
        border-radius: 57px;
        border: 1px solid var(--Border, #dcdcdc);
        background: var(--Foreground, #fff);
      }
    }
    .form-forgot-password {
      color: var(--text-70, #6a6a6a);
      font-family: Outfit;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration-line: underline;
      float: right;
      padding-top: 15px;
    }
    .submit-button-container {
      width: 250px;
      height: 67px;
      margin-left: auto;
      margin-right: auto;
      flex-shrink: 0;
      margin-top: 40px;
      border-radius: 40px;
      background: var(
        --Button---Primary-Gradient,
        linear-gradient(168deg, #ffda46 -110.09%, #ffae00 118.06%)
      );
      box-shadow: 0px 7px 20px 0px rgba(0, 0, 0, 0.06);
    }
    .form-bottom-text {
      color: var(--text-40, #a7a7a7);
      text-align: center;
      font-family: Outfit;
      font-size: 16px;
      padding-top: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      .form-bottom-text-span {
        background: var(
          --Primary,
          linear-gradient(168deg, #ffda46 -110.09%, #ffae00 118.06%)
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: Outfit;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
      }
    }
  }
}
