@import "/src/styles/mixins.scss";

.content-stats {
  background-color: white;
  width: 40%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 700px;
  border-radius: 16px;
  box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.06);
  padding: 30px;
  .stats-component {
    .header-container {
      @include flex(row, space-between, center);

      .title-container {
        .title {
          color: var(--text-100, #000);
          font-family: Outfit;
          font-size: 22px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-bottom: 5px;
        }

        .tags-container {
          @include flex(row, start, center, 5px);
          .tag-group {
            @include flex(row, start, center, 5px);
            .subtitle {
              color: var(--text-40, #a7a7a7);
              font-family: Outfit;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 19.6px; /* 108.889% */
            }

            .dot-image {
              height: 5px;
            }
          }
        }
      }

      .customer-details-container {
        .customer-details-title {
          color: var(--text-70, #6a6a6a);
          text-align: right;
          font-family: Outfit;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 19.6px; /* 122.5% */
          margin-bottom: 5px;
        }

        .customer-details-value {
          color: var(--text-70, #6a6a6a);
          text-align: right;
          font-family: Outfit;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }

    .stats-container {
      padding: 20px 10px;
      @include flex(row, space-between, center);
      flex-wrap: wrap;
      width: 100%;
      .stat-group {
        width: 50%;
        margin: 20px 0 10px 0;
        .stat-title-container {
          @include flex(row, start, center, 5px);
          .stat-icon {
            height: 20px;
          }

          .stat-title {
            color: var(--text-100, #000);
            font-family: Outfit;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        .stat-value-container {
          @include flex(row, start, center, 10px);
          .stat-value {
            text-align: center;
            font-family: Outfit;
            font-size: 28px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            background: var(
              --primary-gradient,
              linear-gradient(168deg, #ffda46 0%, #ffae00 100%)
            );
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          .stat-trend-container {
            @include flex(row, start, center, 5px);
            img {
              height: 20px;
            }

            .stat-trend-value-positive {
              color: var(--constructive, #00bc06);
              text-align: center;
              font-family: Outfit;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
            .stat-trend-value-negative {
              color: var(--destructive, #fc6666);
              text-align: center;
              font-family: Outfit;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
      }
    }
  }
}
