@import "/src/styles/mixins.scss";

.content-approval-table-view {
  padding: 30px;
  .header-container {
    @include flex(row, space-between, center);
    margin-bottom: 20px;
    width: calc(100% - 50px);
    .title-container {
      @include flex(row, start, center, 20px);
      .title {
        color: var(--text-100, #000);
        font-family: Outfit;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .play-content-button {
        background: linear-gradient(168deg, #ffda46 0%, #ffae00 100%);
        padding: 10px 11px 8px 11px;
        border-radius: 50%;
        cursor: pointer;
        filter: drop-shadow(0px 4px 50px rgba(0, 0, 0, 0.2));
        img {
          height: 20px;
        }
      }
    }
    .upload-content-container {
      @include flex(row, start, center, 10px);
      cursor: pointer;
      .text {
        background: var(
          --primary,
          linear-gradient(168deg, #ffda46 0%, #ffae00 100%)
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      img {
        height: 15px;
      }
    }
  }
  .search-area {
    @include flex(row, space-between, center,20px);
    width: calc(100% - 50px);
    .search-container {
      width: 100%;
      .icon-input {
      }
    }
    .tags-container {
      @include flex(row, space-between, center, 10px);
      width: 50%;
      .tag {
        color: var(--text-70, #6a6a6a);
        font-family: Outfit;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        cursor: pointer;
      }
      .colored-tag {
        background: var(
          --primary,
          linear-gradient(168deg, #ffda46 0%, #ffae00 100%)
        );
        cursor: pointer;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  .table-area{
    padding: 20px 0px;
  }
}
