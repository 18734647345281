@import "/src/styles/mixins.scss";

.view-asset-container {
    height: 100%;
    overflow-y: auto;
    padding-left: 30px;
    padding-right: 30px;
.left-side-container {
    padding-left: 100px;
    padding-top: 30px;
    padding-bottom: 30px;
    .title-text {
        color: var(--text-40, #A7A7A7);
        font-family: Outfit;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .name-card {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        width: 500px;
        height: 195px;
        flex-shrink: 0;
        border-radius: 16px;
        background: var(--Foreground, #FFF);
        box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.06);
        padding: 16px;
        .divider {
            width: 468px;
            height: 0.5px;
            flex-shrink: 0;
            border-radius: 16px;
            border: 1px solid var(--Border, #DCDCDC);
            background: var(--Foreground, #FFF);
        }
        .top-row {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items:center;
            .business-name {
                color: var(--text-100, #000);
                font-family: Outfit;
                font-size: 28px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
            .profile-icon-name {
                display: flex;
                flex-direction: row;
                align-items: center;
                .name {
                    color: var(--text-40, #A7A7A7);
                    font-family: Outfit;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
            .profile-icon-name {
                display: flex;
                flex-direction: row;
                align-items: center;
                .icons {
                    padding-right: 10px;
                }
            }
        }
    }
    .bottom-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-top: 16px;
        .views {
            display: flex;
            flex-direction: column;
            justify-content: center;
            .icon-name {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-right: 20px;
                .icon {
                    padding-right: 7px;
                }
            }
            .numeric-value {
                font-family: Outfit;
                font-size: 28px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                background: var(--Primary-Gradient, linear-gradient(168deg, #FFDA46 -110.09%, #FFAE00 118.06%));
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                padding-top: 5px;
            }
        }
        .shares {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 30px;
            padding-right: 30px;
            .icon-name {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-right: 20px;
                .icon {
                    padding-right: 7px;
                }
            }
            .numeric-value {
                font-family: Outfit;
                font-size: 28px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                background: var(--Primary-Gradient, linear-gradient(168deg, #FFDA46 -110.09%, #FFAE00 118.06%));
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                padding-top: 5px;
            }
        }
        .saves {
            display: flex;
            flex-direction: column;
            justify-content: center;
            .icon-name {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-right: 20px;
                .icon {
                    padding-right: 7px;
                }
            }
            .numeric-value {
                font-family: Outfit;
                font-size: 28px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                background: var(--Primary-Gradient, linear-gradient(168deg, #FFDA46 -110.09%, #FFAE00 118.06%));
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                padding-top: 5px;
            }
        }
    }
    .container-boost-visibility {
        box-sizing: border-box;
        width: 500px;
        height: 226px;
        flex-shrink: 0;
        border-radius: 16px;
        background: var(--Foreground, #FFF);
        box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.06);
        padding: 20px;
        margin-top: 10px;
        margin-bottom: 10px;
        .top-row {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: left;
            .title-content {
                padding-left: 10px;
                .title {
                    color: var(--text-100, #000);
                    font-family: Outfit;
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
                .description {
                    color: var(--text-40, #A7A7A7);
                    font-family: Outfit;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    padding-top: 5px;
                }
            }
        }
        .bottom-button {
            padding-top: 24px;
        }
    }
    .stats-title {
        color: var(--text-40, #A7A7A7);
        font-family: Outfit;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .performance-container {
        box-sizing: border-box;
        width: 500px;
        flex-shrink: 0;
        .first-row {
            display: flex;
            flex-direction: row;
            justify-content:space-between;
            align-items: baseline;
            margin-top: 16px;
            margin-bottom: 16px;
        .impressions {
            box-sizing: border-box;
            width: 242px;
            height: 94px;
            flex-shrink: 0;
            border-radius: 16px;
            background: var(--Foreground, #FFF);
            box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.06);
            padding: 16px;
            .top-row {
                display: flex;
                flex-direction: row;
                align-items: center;
            }
            .text {
                color: var(--text-100, #000);
                font-family: Outfit;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                padding-left: 7px;
            }
            .bottom-row {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-top: 5px;

                .numeric-value {
                    text-align: center;
                    font-family: Outfit;
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    background: var(--Primary-Gradient, linear-gradient(168deg, #FFDA46 -110.09%, #FFAE00 118.06%));
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
                .percent-red-value {
                    color: var(--DESTRUCTIVE, #FC6666);
                    text-align: center;
                    font-family: Outfit;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
                .percent-value {
                    color: var(--Constructive, #00BC06);
                    text-align: center;
                    font-family: Outfit;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
                .arrow-icon {
                    padding-left: 5px;
                    padding-right: 5px;
                }
            }
        }
    }
    }
}

.right-side-container {
    padding-right: 100px;
    padding-top: 30px;
    padding-bottom: 30px;
    display: block;
    .btn-container{
        margin: 15px;
       // width: 100%;
        button{
            float: right;
        }
    }
    .video-container {
        justify-content: end;
        .video-alignment {
            align-items: center;
        }
    }
}
}
