@import "/src/styles/mixins.scss";
.subscription {
  @include flex(column, center, center);
  padding: 40px 10%;
  .title {
    color: var(--text-100, #000);
    text-align: center;
    font-family: Outfit;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 5px;
  }

  .sub-title {
    color: var(--text-40, #a7a7a7);
    text-align: center;
    font-family: Outfit;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.6px; /* 108.889% */
  }

  .subscription-card-container {
    @include flex(row, center, start,20px);
    padding: 20px 0;
  }
}
