@import "/src/styles/mixins.scss";

.edit-thumbnail-page {
  // Add any styles for the edit-thumbnail-page container here
  background-color: #0c0c0f;
  @include flex(row, center, start);
  height: calc(100% + 10px);
  position: relative;
  padding-bottom: 20px;

  .edit-thumbnail-content-area {
    // Add any styles for the edit-thumbnail-content-area container here
    width: 40%;
    @include flex(row, center, start);
    position: relative;
    height: 100%;

    .edit-thumbnail-bg {
      // Add any styles for the edit-thumbnail background image here
      height: 70vh;
      position: absolute;
      z-index: 1;
      border-radius: 0px 0px 22px 22px;
      background-color: linear-gradient(180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.7) 46.88%,
          #000 100%);
    }

    .video-bg {
      height: 70vh;
      width: 40vh;
      position: absolute;
      z-index: 1;
      border-radius: 22px;
      overflow: hidden;
      top: 30px;
      background-color: linear-gradient(180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.7) 46.88%,
          #000 100%);
    }

    .thumbnail-slider {
      // Add any styles for the thumbnail slider image here
      position: absolute;
      bottom: 32vh;
      width: 40%;
      z-index: 3;

      .MuiSlider-rail {
        height: 80px;
        background-color: #fff;
        opacity: 0.2;
        backdrop-filter: blur(10px);
      }

      .MuiSlider-track {
        height: 80px;
        background-color: #000;
        opacity: 0.4;
        backdrop-filter: blur(10px);
        border: none;
      }

      .MuiSlider-thumb {
        height: 80px;
        width: 4px;
        background-color: #fff;
        box-shadow: none;
        border-radius: 0px;

        &::after {
          height: 80px;
          width: 2px;
          background-color: #fff;
        }
      }
    }

    .title-text {
      // Add any styles for the title text here
      color: #fff;
      font-family: Outfit;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: 25.2px;
      /* 114.545% */
      position: absolute;
      bottom: 23vh;
      z-index: 3;
    }

    .subtitle-text {
      // Add any styles for the subtitle text here
      position: absolute;
      bottom: 20vh;
      z-index: 3;
      text-align: center;
      font-family: Outfit;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 19.6px;
      /* 108.889% */
      letter-spacing: -0.408px;
      text-decoration-line: underline;
      background: var(--button-primary-gradient,
          linear-gradient(168deg, #ffda46 0%, #ffae00 100%));
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .next-button-container {
    // Add any styles for the next-button-container here
    position: absolute;
    z-index: 4;
    bottom: 40px;
    width: 250px;
    left: 50%;
    transform: translateX(-50%);
  }
}

// --------------------- IMAGE UPLOAD TEXT STYLES ---------------------

.custom-image-upload-button {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
}

.custom-image-upload-button input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}

.custom-image-upload-button:hover {
  text-decoration: underline;
}