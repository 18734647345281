@import "/src/styles/mixins.scss";
.profile-requests-page {
  @include flex(row, center, start);
  .list-button {
    cursor: pointer;
    background: var(
      --primary,
      linear-gradient(168deg, #ffda46 0%, #ffae00 100%)
    );
    filter: drop-shadow(0px 4px 50px rgba(0, 0, 0, 0.2));
    padding: 9px 10px;
    border-radius: 30px;
    img {
      height: 20px;
    }
  }
  .content-container {
    width: 45%;
    padding: 30px;
    height: calc(100vh - 120px);
    border-left: 1px solid #dcdcdc;
    border-right: 1px solid #dcdcdc;
    .MuiOutlinedInput-notchedOutline {
      border: none;
      outline: none;
      padding: 0 !important;
    }
    .MuiInputBase-root {
      font-family: Outfit;
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 0em;
      text-align: left;
      padding: 0px 15px 0 0;
      border-radius: 50px;
    }
    .header-container {
      @include flex(row, start, center,10px);
      margin-bottom: 20px;
      .filters-container {
        @include flex(row, start, center, 15px);
        .filter {
          font-family: Outfit;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0em;
          text-align: left;
          color: #6a6a6a;
          cursor: pointer;
        }
        .selected-filter {
          font-family: Outfit;
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: 0em;
          text-align: left;
          cursor: pointer;
          color: #ffae00;
        }
      }
    }
    .search-container {
      width: 95%;
    }
    .cards-container{
      overflow-y: scroll;
      height: 600px;
      margin: 30px 0;
      @include hideScrollbar();
    }
  }
}
