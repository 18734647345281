@import "/src/styles/mixins.scss";

.add-tags-component {
  height: calc(100% + 10px);
  background-color: #0c0c0f;
  @include flex(row, center, start);

  .add-tags-content-area {
    width: 40%;
    @include flex(column, start, center);

    .video {
      // Video thumbnail styles
      height: 225px;
      margin: 15px auto;
      border-radius: 16px;
    }
    .input-group {
      width: 100%;
      margin: 15px 0;
      .input-label-title {
        // Input label title styles
        color: #fff;
        font-family: Outfit;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 5px;
      }

      .input-label-description {
        // Input label description styles
        color: #fff;
        font-family: Outfit;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        opacity: 0.4;
        margin-bottom: 10px;
      }
    }

    .tags-container {
      // Tags container styles
      @include flex(row, start, start, 10px);
      flex-wrap: wrap;
      width: 100%;
      height: 220px;
      overflow-y: auto;
      .tag {
        // Individual tag styles
        border-radius: 64px;
        border: 1px solid var(--text-field-border, #1f1f29);
        background-color: var(--foreground, #101014);
        padding: 14px 20px;
        color: rgba(255, 255, 255, 0.7);
        text-align: center;
        font-family: Outfit;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 19.6px; /* 108.889% */
        cursor: pointer;
        transition: all 0.2s ease-in-out;
      }
      .tag-selected {
        // Individual tag styles
        border-radius: 64px;
        border: 1px solid #ffae00;
        background-color: var(--foreground, #101014);
        padding: 14px 20px;
        color: #ffae00;
        text-align: center;
        font-family: Outfit;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        transition: all 0.2s ease-in-out;
        line-height: 19.6px; /* 108.889% */
        cursor: pointer;
      }
    }
  }
  .submit-button-container {
    width: 250px;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
  }
}
