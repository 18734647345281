@import "/src/styles/mixins.scss";
@import "/src/styles/variables.scss";

.video-overview-card {
  // Your styles for .video-overview-card here
  position: relative;
  height: 315px;
  cursor: pointer;
  @include hoverEffect(1.02);
  .video-image {
    // Your styles for .video-image here
    position: absolute;
    width: 100%;
    height: 315px;
   // z-index: -1;
    border-radius: 16px;
  }

  .video-details-container {
    // Your styles for .video-details-container here
    height: 315px;
    position: relative;

    .top-area {
      // Your styles for .top-area here
      position: absolute;
      top: 0;
      padding: 10px;
      width: calc(100% - 20px);
      @include flex(row, space-between, center);

      .top-area-row {
        display: flex;
        gap: 3px;
      }

      .pending {
        border-radius: 76px;
        background: rgba(0, 0, 0, 0.8);
        padding: 8px 10px;
        color: #fff;
        font-family: Outfit;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .draggable-icon {
        // Your styles for .draggable-icon here
        width: 25px;
        cursor: pointer;
      }

      .trash-icon {
        width: 20px;
        cursor: pointer;
      }

      .trash-icon-bg {
        width: 25px; /* Adjust the width and height as needed */
        height: 30px;
        background-color: red;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .views-container {
        // Your styles for .views-container here
        @include flex(row, start, center, 10px);
        border-radius: 76px;
        background: rgba(0, 0, 0, 0.5);
        padding: 10px;
        .views {
          color: #fff;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 15.4px; /* 96.25% */
        }

        .video-icon {
          // Your styles for .video-icon here
          width: 15px;
        }
      }
    }

    .center-area{
      position: absolute;
      top: 35%;
      padding: 10px;
      width: calc(100% - 20px);
      display: flex;
      align-items: center;
      justify-content: center;
      .select-icon{
        margin: auto !important;
        width: 80px;
        height: 80px;
      }
    }

    .bottom-area {
      // Your styles for .bottom-area here
      position: absolute;
      bottom: 0;
      padding: 10px;
      width: calc(100% - 20px);
      @include flex(row, space-between, end);
      border-radius: 0px 0px 8px 8px;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.3) 35.42%,
        rgba(0, 0, 0, 0.7) 100%
      );
      .title {
        color: #fff;
        font-family: Outfit;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 24px */
        // width: 0px;
      }

      .price {
        // Your styles for .price here
        color: var(--constructive, #00bc06);
        text-align: right;
        font-family: Outfit;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 24px */
      }
    }
  }
}
