@import "/src/styles/mixins.scss";

.container-gallery{
    height: 100%;
    // overflow-y: auto;
    border-right: 1px solid var(--Border, #DCDCDC);
    background: var(--Foreground, #FFF);
.left-side-bar::-webkit-scrollbar {
    display: none;
}
.left-side-bar{
    border-right: 1px solid rgba(220, 220, 220, 1);
    height: 100vh;
    overflow-y: scroll;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 25px;
    padding-bottom: 100px;
    .sub-menu-item {
        color: var(--text-70, #6A6A6A);
        font-family: Outfit;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        // line-height: 28]px; /* 155.556% */
        padding-left: 35px;
        cursor: pointer;
        &:hover {
            border-radius: 8px;
            background: rgba(0, 0, 0, 0.05);
          }
    }
    .menu-list-item{
        &:hover {
            border-radius: 8px;
            background: rgba(0, 0, 0, 0.05);
          }
          &:active {
            border-radius: 8px;
            background: rgba(0, 0, 0, 0.05);
          }
    }
    .category-sneak-piq {
        color: var(--text-100, #000);
        font-family: Outfit;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .side-bar-divider {
        width: 100%;
        height: 1px;
        background: #DCDCDC;
        margin-top: 10px;
        margin-bottom: 10px;
    } 
    .menu-item-breakfast {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: var(--text-70, #6A6A6A);
        font-family: Outfit;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 155.556% */
        padding-top: 5px;
        cursor: pointer;
    }
    .menu-title-all {
        color: var(--text-100, #000);
        font-family: Outfit;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px; /* 155.556% */
        border-radius: 8px;
        background: rgba(0, 0, 0, 0.05);
        margin-bottom: 5px;
    }
    .title-menu {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .menu-icons{
            display: flex;
            flex-direction: row;
            align-items: baseline;
            .add-icon {
                padding-right: 5px;
                margin-right: 5px;
                cursor: pointer;
            }
            .edit-icon {
                cursor: pointer;
            }
        }
        .menu-text {
            color: var(--text-100, #000);
            font-family: Outfit;
            font-size: 22px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            cursor: pointer;
        }
    }
.title-text{
    color: var(--text-100, #000);
    font-family: Outfit;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.menu-item-list {
    display: flex;
    flex-direction: column;
    justify-content: start;
}
.cursor-pointer{
    cursor: pointer;
}
}
.right-side-section{
    height: 100vh;
    overflow-y: scroll;

.right-side-container {
    height: auto;
    overflow-y: auto;
}
}
.right-side-section::-webkit-scrollbar {
    display: none;
}
}

