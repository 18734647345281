@import "/src/styles/mixins.scss";

.single-video-playback {
  img {
    width: 250px;
    height: 445px;
    border-radius: 16px;
    object-fit: cover;
    z-index: 3;
  }
  .video-container {
    background-color: #0C0c0f;
    border-radius: 16px;
    .video-player {
      width: 250px;
      height: 445px;
      border-radius: 16px;
      overflow: hidden;
      cursor: pointer;
      z-index: 2;
      
    }
    transition: 0.7s ease-out;
    position: relative;
    .video-content-outer {
      position: absolute;
      height: 445px;
      width: 250px;
    }
    .video-content {
      position: relative;
      height: inherit;
      cursor: pointer;
      width: inherit;
      .header-container {
        @include flex(row, start, start, 15px);
        width: 220px;
        height: 100px;
        // padding: 15px;
        position: absolute;
        top: 0;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.8) 0%,
          rgba(0, 0, 0, 0.35) 46.88%,
          rgba(0, 0, 0, 0) 100%
        );
        border-radius: 16px;
        margin-bottom: 200px;

        img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }

        .text {
          color: #fff;
          text-align: center;
          font-family: Outfit;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }

      .bottom-container {
        // padding: 15px;
        border-radius: 0px 0px 16px 16px;
        width: calc(250px - 30px);
        position: absolute;
        bottom: 0;
        // @include flex(column, end, start, 15px);

        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.35) 46.88%,
          rgba(0, 0, 0, 0.8) 100%
        );
        .heading {
          color: #fff;
          font-family: Outfit;
          font-size: 22px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%; /* 33px */
        }

        .tags-container {
          @include flex(row, start, center, 5px);
          margin-top: 10px;
          max-width: 220px;
          overflow-x: scroll;

          @include hideScrollbar();
          .tag-group {
            @include flex(row, start, center, 5px);
            .tag-image {
              width: 20px;
            }
            .tag-text {
              color: #fff;
              font-family: Outfit;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 19.6px; /* 140% */
            }

            .divider {
              width: 5px;
              height: 5px;
            }
          }
        }

        .description-container {
          margin-top: 10px;
          // height: 110px;
          max-height: 60px;
          overflow-y: scroll;

          @include hideScrollbar();
          .description-text {
            color: #fff;
            font-family: Outfit;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 19.6px; /* 122.5% */
            z-index: 3;
          }
        }
      }
    }
  }
}
