@import "/src/styles/mixins.scss";

.single-video-view-asset {
  box-sizing: border-box;
  img {
    width: 370px;
    height: 830px;
    flex-shrink: 0;
    border-radius: 16px;
    object-fit: cover;
    z-index: 3;
  }
  .video-view-container {
    background-color: rgba(12, 12, 15, 1);
    border-radius: 16px;
    width: 100%;
    .video-player {
    width: 350px;
    height: 830px;
    flex-shrink: 0;        
    border-radius: 16px;
    overflow: hidden;
    cursor: pointer;
    z-index: 2;
    }
    transition: 0.7s ease-out;
    position: relative;
    .video-content-outer {
      position: absolute;
      width: 350px;
      height: 810px;
      flex-shrink: 0;
    }
    .video-content {
      position: relative;
      height: inherit;
      cursor: pointer;
      width: 100%;
      justify-content: space-between;
      .header-container {
        @include flex(row, center, start);
        width: 100% !important;
        height: 810px;
        flex-shrink: 0;
        padding: 15px;
        position: absolute;
        top: 0;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.8) 0%,
          rgba(0, 0, 0, 0.35) 46.88%,
          rgba(0, 0, 0, 0) 100%
        );
        border-radius: 16px;
        margin-bottom: 200px;
        .piq-log-icon {
          width: 40px;
          height: 40px;
          margin-top: 100px;
        }
        .text {
          color: #fff;
          text-align: center;
          font-family: Outfit;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }

      .bottom-container {
        width: 100% !important;
        padding-left: 15px;
        padding-right: 15px;
        border-radius: 0px 0px 16px 16px;
        width: calc(250px - 30px);
        position: absolute;
        bottom: 0;
        // @include flex(column, end, start, 15px);

        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.35) 46.88%,
          rgba(0, 0, 0, 0.8) 100%
        );
        .heading {
          color: #fff;
          font-family: Outfit;
          font-size: 22px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%; /* 33px */
        }

        .tags-container {
          @include flex(row, start, center, 5px);
          margin-top: 10px;
          max-width: 100%;
          overflow-x: scroll;
          @include hideScrollbar();
          .tag-group {
            @include flex(row, start, center, 5px);
            .tag-image {
              width: 20px;
            }
            .tag-text {
              color: #fff;
              font-family: Outfit;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 19.6px; /* 140% */
            }

            .divider {
              width: 5px;
              height: 5px;
            }
          }
        }

        .description-container {
          margin-top: 10px;
          max-height: 200px;
          .percentage-tags-container {
            display: flex;
            flex-direction: row;
            align-items: baseline;
            padding-bottom: 0px;
            padding-top: 0px;
            margin-bottom: 5px;
            .percentage {
              font-size: 12px;
              color: green;
              font-family: Outfit;
              font-weight: 800;
            }
            .tags ul {
              font-size: 12px;
              list-style: none;
              padding: 0;
              display: flex;
              flex-wrap: wrap;
            }

            .tags ul li::before {
              content: "•";
              color: gray;
              display: inline-block;
              width: 1em;
              margin-right: -3px;
              margin-left: 4px;
            }

            .tags ul li {
              color: gray;
              margin-bottom: 8px;
            }
          }
          .bottom-top-icon-name {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-bottom: 5px;
            .icon {
              width: 30px;
              height: 30px;
            }
            .name {
              color: white;
              font-size: 10px;
              font-family: Outfit;
              font-style: normal;
              font-weight: 400;
            }
          }
          .divider {
            width: 90px;
            height: 2px;
            font-weight: 700;
            border-radius: 12px;
            text-align: center;
            color: white;
            background-color: white;
            justify-content: center;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            margin-top: 10px;
          }
          .title-icon-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding-top: 0px;
            padding-bottom: 10px;
            .title {
              font-size: 14px;
              color: white;
              font-weight: 700;
            }
            .save-icon {
              color: white;
              fill: white;
            }
          }
          .description-text {
            color: #fff;
            font-family: Outfit;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 19.6px;
            z-index: 3;
            display: flex;
            flex-direction: column;
          }
          .bottom-logo-lable {
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            justify-content: left;
            .logo {
              width: 56px;
              height: 48px;
              border: 1px solid #ffae00;
              border-radius: 25px;
              margin-right: 10px;
            }
            .description-text {
              font-size: 15px;
              padding-left: 5px;
            }
            .bottom-lable {
              width: 100%;
              height: 50px;
              box-sizing: border-box;
              border: 1px solid #ffae00;
              display: flex;
              flex-direction: row;
              border-radius: 25px;
              padding-top: 3px;
              padding-bottom: 3px;
              padding-left: 10px;
              padding-right: 5px;
              align-items: center;
              .shoping-icon {
                color: #ffae00;
                fill: #ffae00;
                background-color: #ffae00;
                padding-right: 4px;
                border-radius: 8px;
              }
            }
          }
        }
      }
    }
  }
}
