@import "/src/styles/mixins.scss";

.primary-button {
  border-radius: 40px;
  background: var(--primary, linear-gradient(168deg, #ffda46 0%, #ffae00 100%));
  box-shadow: 0px 7px 20px 0px rgba(0, 0, 0, 0.06);
  border: none;
  width: 100%;
  height: 65px;
  color: #fff;
  padding: 0;
  text-align: center;
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  &:hover{
    transform: scale(1.03);
  }
}
.primary-button:disabled{
  background: var(--primary-disabled, #e0e0e0);
  cursor: not-allowed;
}

.primary-button-input {
  border-radius: 40px;
  background: var(--primary, linear-gradient(168deg, #ffda46 0%, #ffae00 100%));
  box-shadow: 0px 7px 20px 0px rgba(0, 0, 0, 0.06);
  border: none;
  width: 100%;
  height: 70px;
  color: #fff;
  padding: 12px 45px;
  text-align: center;
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  &:hover{
    transform: scale(1.03);
  }
}
.primary-button-input:disabled{
  background: var(--primary-disabled, #e0e0e0);
  cursor: not-allowed;
}