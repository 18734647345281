.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: calc(100vw - 120px);
  height: 47px;
  padding: 10px 60px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  background: var(--background, #0c0c0f);
  .social-icon {
    height: 30px;
    width: 30px;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      transform: scale(1.06);
    }
  }
  .left-container {
    display: flex;
    align-items: center;
    gap: 20px;
    .footer-text {
      opacity: 0.7;
      color: #fff;
      font-size: 18px;
      font-family: Satoshi;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        transform: scale(1.04);
        opacity: 1;
      }
    }
  }
  .middle-container {
    position: absolute;
    left: calc(50% - 55px);
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .right-container {
    display: flex;
    align-items: center;
    gap: 30px;
    .sub-container {
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        transform: scale(1.04);
        .footer-text {
          opacity: 1;
        }
      }
      display: flex;
      align-items: center;
      gap: 5px;
    }
    .footer-text {
      opacity: 0.7;
      color: #fff;
      font-size: 18px;
      font-family: Satoshi;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      transition: 0.3s;
    }
  }
}
